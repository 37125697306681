import React, { useRef, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { API } from "../../utils";

import { Toast } from 'primereact/toast';
import EmojiPicker from 'emoji-picker-react';
import { Messages } from 'primereact/messages';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";   

import { ReactComponent as LinkIcon } from "../../images/svg/link.svg";
import { ReactComponent as SmileIcon } from "../../images/svg/smile.svg";
import { ReactComponent as HashtagIcon } from "../../images/svg/hastag.svg"

import globeIcon from "../../images/svg/Globe.svg";
import avatarIcon from "../../images/images/avatar.svg"
import PhotoIcon from "../../images/svg/photo_empty.svg";
import uploadPhoto from "../../images/svg/upload_photo.svg";


const CreatePost = (props) => {

    const msgs = useRef(null);
    const toast = useRef(null);
    const { pageId } = useParams();     
    const { t } = useTranslation();

    const [getTitre, setTitre] = useState('');
    const [getTexte, setTexte] = useState('');

    const [preview, setPreview] = useState(null);
    const [previewThumbnail, setPreviewThumbnail] = useState(null);

    const [isReel, setIsReel] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [isStorie, setIsStorie] = useState(false);
    const [storieVideo, setStorieVideo] = useState(false);

    const [getPreviewUrl, setPreviewUrl] = useState('');

    const [getError, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isHashtag, setIsHashtag] = useState(false);
    const [getValueLink, setValueLink] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedReel, setSelectedReel] = useState(null);    
    const [selectedStorie, setSelectedStorie] = useState(null);
    const [selectedReelThumbnail, setSelectedReelThumbnail] = useState(null);
    
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
    
    const page = props?.pages?.find(page => page.page_id === parseInt(pageId));
    
    const name = page?.page?.name ?? '';
    const picture = page?.page?.picture ?? avatarIcon;
    const socialType = page ? page.social_type : 'Inconnu';  

    useEffect(() => {
        if (msgs.current) {
            msgs.current.clear();
            msgs.current.show([
                { sticky: true, severity: 'error', detail: getError, closable: true }
            ]);
        }
    }, [getError]);

    const showError = (errorMessage) => {
        toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: errorMessage,
            life: 3000
        });
    };

    const handleThumbnailChange = (event) => {

        const file = event.target.files[0];
        if (!file) return;

        setSelectedReelThumbnail(file)
        
        const previewUrl = URL.createObjectURL(file);
        setPreviewThumbnail(previewUrl); 
    };
    
    const handleFileChange = (event) => {
        setSelectedReel(null);
        setSelectedStorie(null);

        const file = event.target.files[0];
        if (!file) return;
    
        // Vérifiez si le fichier est une vidéo
        if (file.type.startsWith('video/')) {
          console.log('Le fichier est une vidéo:', file.name);
          setIsVideo(true); // Affiche l'élément pour les vidéos
        } else {
          console.log('Le fichier n\'est pas une vidéo:', file.name);
          setIsVideo(false); // Masque l'élément pour les autres types de fichiers
        }
        setSelectedFile(file);

        if (file) {
          const previewUrl = URL.createObjectURL(file);
          setPreview(previewUrl);
        }
    };
    
    const handleReelChange = (event) => {
        setSelectedFile(null);
        setSelectedStorie(null);

        const file = event.target.files[0];
        if (!file) return;
        
        setIsReel(true);
        setSelectedReel(file);

        if (file) {
          const previewUrl = URL.createObjectURL(file);
          setPreview(previewUrl);
        }
    };

    const handleStorieChange = (event) => {
        setSelectedFile(null);
        setSelectedReel(null);

        const file = event.target.files[0];
        if (!file) return;
    
        // Vérifiez si le fichier est une vidéo
        if (file.type.startsWith('video/')) {
          console.log('Le fichier est une vidéo:', file.name);
          setStorieVideo(true); // Affiche l'élément pour les vidéos
        } else {
          console.log('Le fichier n\'est pas une vidéo:', file.name);
          setStorieVideo(false); // Masque l'élément pour les autres types de fichiers
        }

        setIsStorie(true);
        setSelectedStorie(file);

        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setPreview(previewUrl);
          }
    };

    const clearFile = () => {
        setTitre('');
        setTexte(''); // Réinitialise le texte
        setValueLink(''); // Réinitialise le lien
        setIsReel(false);
        setIsVideo(false);
        setIsStorie(false);
        setStorieVideo(false); // Réinitialise storieVideo
        setIsHashtag(false); // Réinitialise isHashtag
        setSelectedFile(null); // Réinitialise le fichier sélectionné
        setSelectedReel(null); // Réinitialise le reel sélectionné
        setSelectedStorie(null); // Réinitialise la storie sélectionnée
        setSelectedReelThumbnail(null); // Réinitialise la thumbnail sélectionnée
    
        // Rafraîchir les inputs file en les remettant à vide temporairement
        const fileInput = document.getElementById("upload_file");
        if (fileInput) {
            fileInput.value = "";
        }
    
        const reelInput = document.getElementById("upload_reel");
        if (reelInput) {
            reelInput.value = "";
        }
    
        const reelThumbnailInput = document.getElementById("upload_thumbnail");
        if (reelThumbnailInput) {
            reelThumbnailInput.value = "";
        }
    
        const storieInput = document.getElementById("upload_storie");
        if (storieInput) {
            storieInput.value = "";
        }
    };    

    const clearThumbnail = () => {
        
        setPreviewThumbnail(null); 
        setSelectedReelThumbnail(null); 
    
        const reelThumbnailInput = document.getElementById("upload_thumbnail");
        if (reelThumbnailInput) {
            reelThumbnailInput.value = "";
        }
    };    

    const onChangeTexte = (e) => {
      setTexte(e.target.value);
    };

    const toggleEmojiPicker = () => {
        setIsEmojiPickerOpen(prevState => !prevState);
    };

    const onEmojiClick = (event, emojiObject) => {
        setTexte(prevValue => prevValue + event.emoji);
        //setTextePreview(prevValue => prevValue + event.emoji);
    }

    // Fonction pour soumettre les données avec gestion du chargement
    const handleSubmit = async () => {
        setIsLoading(true); 

        const formData = new FormData();
        formData.append("pageId", pageId);
        formData.append("isReel", isReel);
        formData.append("titre", getTitre);
        formData.append("texte", getTexte);
        formData.append("isVideo", isVideo);
        formData.append("link", getValueLink);
        formData.append("isStorie", isStorie);
        formData.append("storieVideo", storieVideo);

        if (selectedReelThumbnail) {
            formData.append('thumbnail', selectedReelThumbnail); 
        }
        if (selectedFile) {
            formData.append("file", selectedFile);
        } else if (selectedReel) {
            formData.append("file", selectedReel);
        } else if (selectedStorie) {
            formData.append("file", selectedStorie);
        }
        
        try {
            // Envoi de la requête à l'API
            let response;
            response = await API.planificates.publishFB(formData);                

            if (isStorie && response?.data?.status == "error") {
                const errorMessage = response?.data?.msg_error.replace(/visit https:\/\/[^\s]+/, '').trim() || 'No error message available.';
                console.log(errorMessage);
                setError(errorMessage);
                setIsLoading(false);

            } else if (isReel && response?.data?.statusResponse?.status?.video_status == "error") {
                const errorMessage = response?.data?.statusResponse?.status?.processing_phase?.errors[0]?.message.replace(/visit https:\/\/[^\s]+/, '').trim() || 'No error message available.';
                console.log(errorMessage);
                setError(errorMessage);
                setIsLoading(false);
            } else if (response?.status === 200) {

                if (isReel) {
                    setPreviewUrl(`https://www.facebook.com/reel/${response?.data?.videoId}`);                    
                } else if (isStorie) {
                    setPreviewUrl(`https://www.facebook.com/stories/${response?.data?.post_id}`);                    
                } else if (isVideo) {
                    setPreviewUrl(`https://www.facebook.com/watch/?v=${response?.data?.video_id}`);
                } else {
                    setPreviewUrl(`https://www.facebook.com/${response?.data?.id}`);
                }
                console.log('Publication réussie:', response.data);
                toast.current.show({
                    severity: 'success',
                    summary: 'Succès',
                    detail: 'Publication effectuée avec succès',
                    life: 3000
                });

                // Réinitialisation des champs après la publication
                clearFile();
                clearThumbnail();
            } else {
                // Gestion des erreurs de l'API
                const errorMessage = response?.data?.error?.message || "Une erreur est survenue";
                setError(errorMessage);
                setIsLoading(false);
                console.error('Erreur lors de la publication:', errorMessage);
            }
        } catch (error) {
            // Gestion des erreurs inattendues
            const errorMessage = error?.response?.data?.error?.message || "Une erreur inattendue est survenue";
            showError(errorMessage);
            setIsLoading(false);
            console.error('Erreur de soumission:', errorMessage);
        } finally {
            // Désactive le chargement après la réponse ou en cas d'erreur
            setIsLoading(false);
        }

        // Affiche les valeurs de formData pour vérification
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }
    };

    const MAX_FILENAME_LENGTH = 7;
    const MAX_FILENAMEREEL_LENGTH = 30;       
    
    return (
        <div className="block_createPost">
            <Toast ref={toast}></Toast>

            <h1 className="font-semibold text-[#101828] text-[30px] pb-4 mb-6 border-b-2 border-[#EAECF0]">{t("Planification.create_post")}</h1>
            <div className="grid grid-cols-2 gap-4">
                <div className="col border-r-2 border-[#EAECF0] relative">
                    <div className="publishPost px-6">
                        <div className="multimedia_content px-8 py-4">
                            <h2 className="font-semibold text-[#101828] text-[18px]">{t("Planification.multimedia_content")}</h2>
                            <span className="font-normal text-[#344054] text-[12px] block my-2">{t("Planification.share_photos_video")}</span>

                            <div className="columns-2">
                                <div className="btn_style_upload flex bg-white border border-[#D0D5DD] rounded-[8px] rop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)]">
                                    <label htmlFor="upload_file" className="flex items-center justify-center px-4 py-2 font-semibold text-[#344054] text-[14px] w-full cursor-pointer">
                                        <img className="mr-2" src={uploadPhoto} />
                                        {selectedFile 
                                            ? (selectedFile.name && selectedFile.name.length > MAX_FILENAME_LENGTH 
                                                ? `${selectedFile.name.slice(0, MAX_FILENAME_LENGTH)}...` 
                                                : selectedFile.name)
                                            : t("Planification.add_photo")}
                                    </label>

                                    {/* Bouton Effacer pour supprimer le fichier */}
                                    {selectedFile && (
                                    <button 
                                        onClick={clearFile} 
                                        className="m-auto p-2 text-[12px] pi pi-times"
                                    >
                                    </button>
                                    )}
                                    <input 
                                        type="file" 
                                        id="upload_file" 
                                        className="hidden"
                                        accept="image/*,video/*"
                                        onChange={handleFileChange} 
                                    />
                                </div>
                                
                                <div className="btn_style_upload flex bg-white border border-[#D0D5DD] rounded-[8px] rop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)]">
                                    <label htmlFor="upload_reel" className="flex items-center justify-center px-4 py-2 font-semibold text-[#344054] text-[14px] cursor-pointer w-full">
                                        <span className="mr-2 pi pi-video"></span>
                                        {selectedReel 
                                            ? (selectedReel.name && selectedReel.name.length > MAX_FILENAME_LENGTH 
                                                ? `${selectedReel.name.slice(0, MAX_FILENAME_LENGTH)}...` 
                                                : selectedReel.name)
                                            : t("Planification.reel")}
                                    </label>
                                        
                                    {/* Bouton Effacer pour supprimer le fichier */}
                                    {selectedReel && (
                                        <button 
                                            onClick={clearFile} 
                                            className="m-auto p-2 text-[12px] pi pi-times"
                                        >
                                        </button>
                                    )}
                                    <input 
                                        type="file" 
                                        id="upload_reel" 
                                        accept="video/*"
                                        className="hidden"
                                        onChange={handleReelChange} 
                                    />                                    
                                </div>
                                
                            </div>
                            
                            <div className="create_story flex bg-white border border-[#D0D5DD] rounded-[8px] rop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05) mt-2 min-h-[50px]">
                                <label htmlFor="upload_storie" className="flex items-center px-4 py-1 cursor-pointer w-full">
                                    {selectedStorie
                                        ? (selectedStorie.name && selectedStorie.name.length > MAX_FILENAMEREEL_LENGTH 
                                            ? `${selectedStorie.name.slice(0, MAX_FILENAMEREEL_LENGTH)}...` 
                                            : selectedStorie.name)
                                        : 
                                        <>
                                        <span className="bg-[#f9f5ff] text-[#6941c6] text-[12px] w-7 h-7 mr-5 rounded-full !flex items-center justify-center pi pi-plus"></span>
                                        <div>
                                            <span className="font-semibold text-[#344054] text-[14px] block">{t("Planification.create_story")}</span>
                                            <span className="font-normal text-[#344054] text-[12px] block">{t("Planification.create_story_txt")}</span>
                                        </div>
                                        </>
                                    }
                                    
                                </label>

                                {/* Bouton Effacer pour supprimer le fichier */}
                                {selectedStorie && (
                                <button 
                                    onClick={clearFile} 
                                    className="m-auto p-2 text-[12px] pi pi-times"
                                >
                                </button>
                                )}
                                <input 
                                    type="file" 
                                    id="upload_storie" 
                                    className="hidden"
                                    accept="image/*,video/*"
                                    onChange={handleStorieChange} 
                                />   
                            </div>

                        </div>
                        
                        {getError && 
                        <div className="block_error px-8">                            
                            <Messages ref={msgs} />
                        </div>
                        }

                        {!isStorie && (
                        <div className="multimedia_content relative px-8 py-4">

                            <h2 className="font-semibold text-[#101828] text-[18px]">{t("Planification.post_details")}</h2>
                            {isVideo && (
                                <>
                                <span className="font-bold text-[#101828] text-[14px] block my-2">
                                    {t("Planification.titre")}
                                </span>
                                <div className="text_publish px-4 border border-[#7F56D9] rounded-[8px] bg-white">
                                    <InputText value={getTitre} onChange={(e) => setTitre(e.target.value)} className="w-full" />
                                </div>
                                </>
                            )}

                            {isReel && (
                            <div className="btn_style_upload my-4 flex bg-white border border-[#D0D5DD] rounded-[8px] rop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)]">
                                <label htmlFor="upload_thumbnail" className="flex items-center justify-center px-4 py-2 font-semibold text-[#344054] text-[14px] w-full cursor-pointer">
                                    <img className="mr-2" src={uploadPhoto} />
                                    {selectedReelThumbnail 
                                        ? (selectedReelThumbnail.name && selectedReelThumbnail.name.length > MAX_FILENAMEREEL_LENGTH 
                                            ? `${selectedReelThumbnail.name.slice(0, MAX_FILENAMEREEL_LENGTH)}...` 
                                            : selectedReelThumbnail.name)
                                        : t("Planification.add_thumbnail")}
                                </label>

                                {/* Bouton Effacer pour supprimer le fichier */}
                                {selectedReelThumbnail && (
                                <button 
                                    onClick={clearThumbnail} 
                                    className="m-auto p-2 text-[12px] pi pi-times"
                                >
                                </button>
                                )}
                                <input 
                                    type="file" 
                                    id="upload_thumbnail" 
                                    className="hidden"
                                    accept="image/*"
                                    onChange={handleThumbnailChange} 
                                />
                            </div>
                            )}

                            <span className="font-bold text-[#101828] text-[14px] block my-2">{t("Planification.text")}</span>
                            
                            <div className="text_publish px-4 py-3 border border-[#7F56D9] rounded-[8px] bg-white">
                                <InputTextarea 
                                    rows={5}
                                    autoResize
                                    value={getTexte} 
                                    onChange={onChangeTexte} 
                                    className="w-full font-normal text-[12px] text-[#000000]"
                                />
                                <div className="option_text flex justify-end">
                                    {isHashtag && (
                                        <button className="mr-2"><HashtagIcon /></button>
                                    )}
                                    <button onClick={toggleEmojiPicker}><SmileIcon /></button>
                                </div>
                                <div className="emoji_block absolute right-0 mx-8 my-4">
                                    <EmojiPicker
                                        open={isEmojiPickerOpen}
                                        searchDisabled
                                        emojiStyle={'facebook'}
                                        onEmojiClick={onEmojiClick} 
                                    />
                                </div>
                            </div>

                        </div>
                        )}
                        
                        {!selectedFile && !selectedReel && !selectedStorie && (
                        <div className="collaborator_block px-8 py-4">
                            <h2 className="font-semibold text-[#101828] text-[18px]">{t("Planification.collaborator")}</h2>
                            <span className="font-normal text-[#344054] text-[12px] block my-2">{t("Planification.collaborator_txt")}</span>
                            <div className="collaborator_txt bg-white rounded-[8px] border border-[#7F56D9] px-4 flex items-center">
                                <LinkIcon />
                                <InputText 
                                    onChange={(e) => setValueLink(e.target.value)}
                                    className="w-full font-normal text-[12px] text-[#000000]" 
                                />
                            </div>
                        </div>
                        )}
                        
                        <div className="scheduling_block px-8 py-4">
                            <h2 className="font-semibold text-[#101828] text-[18px]">{t("Planification.scheduling_options")}</h2>
                            <span className="font-normal text-[#344054] text-[12px] block my-2">{t("Planification.scheduling_txt")}</span>
                            <button 
                                onClick={handleSubmit} 
                                className="font-bold text-[#FFF] text-[12px] bg-[#7F56D9] mt-5 px-5 py-2 rounded-[6px]"
                            >
                                Publish
                            </button>
                        </div>
                    </div>
                    
                    {isLoading &&
                    <div className="loading_block flex items-center justify-center backdrop-blur-[10px] absolute top-0 left-0 right-0 bottom-0">
                        <div className="">
                            <h2 className="font-semibold text-center text-[#101828] text-[16px]">
                                {t("Planification.msg_loading")}<br/>
                                {t("Planification.msg_loading1")}<br/>
                                {t("Planification.msg_loading2")}
                            </h2>
                            <span className="loader"></span>
                        </div>
                    </div>
                    }
                </div>
            
                <div className="col">

                    {getPreviewUrl && (
                    <a
                        href={getPreviewUrl}
                        className="table border border-[#d0d5dd] rounded-[8px] text-[#344054] text-[12px] font-semibold drop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)] px-3 py-3"
                        target="_blank"
                    >
                    {t("Planification.preview_on")} {socialType}
                    </a>
                    )}
                    
                    <div className="name_user my-4">
                        <span className="text-[#344054] font-semibold text-[16px] flex items-center">
                            <img className="w-10 h-10 rounded-full mr-2" src={picture} />
                            {name}
                            <img className="ml-2" src={globeIcon} />
                        </span>
                    </div>

                    <div className="status_post my-4">
                        <span className={`block font-normal text-[12px] text-[#000000] mb-4 ${getTexte ? '' : 'bg-[#F2F4F7] h-2'}`}>{getTexte}</span>
                    </div>

                    <div className="photo_post">
                        <div className="box_photoPost w-full bg-[#F2F4F7] h-[450px] flex items-center justify-center">
                        {selectedStorie ? (
                            selectedStorie.type.startsWith("video") ? (
                                <video
                                    src={preview} // Assurez-vous de créer l'URL d'objet pour selectedStorie
                                    controls
                                    className="max-h-full max-w-full"
                                    alt="Uploaded Storie preview"
                                />
                            ) : (
                                <img
                                    src={preview} // Assurez-vous de créer l'URL d'objet pour selectedStorie
                                    className="max-h-full max-w-full"
                                    alt="Uploaded Storie preview"
                                />
                            )
                        ) : selectedReel ? (
                            <div className="thumbnailReel h-[450px] relative">
                                <video
                                    src={preview}
                                    controls
                                    className="max-h-full max-w-full"
                                    alt="Uploaded Reel preview"
                                />
                                {selectedReelThumbnail && (
                                <img 
                                    src={previewThumbnail}
                                    alt="Uploaded image preview"
                                    className="max-h-full max-w-full w-full h-full absolute top-0 object-cover"
                                />
                                )}
                            </div>
                        ) : selectedFile ? (
                            selectedFile.type.startsWith("video") ? (
                                <video
                                    src={preview}
                                    controls
                                    className="max-h-full max-w-full"
                                    alt="Uploaded video preview"
                                />
                            ) : (
                                <img
                                    src={preview} 
                                    className="max-h-full max-w-full"
                                    alt="Uploaded image preview"
                                />
                            )
                        ) : (
                            <img 
                                src={PhotoIcon} 
                                className="max-h-full max-w-full"
                                alt="Default preview"
                            />
                        )}

                        </div>

                    </div>

                </div>

            </div>

        </div>
    );
};

export default CreatePost;
