import React, { useState, useContext, useEffect } from "react";
import { ReportingRoutes } from "../../routes";
import Welcome from "../Welcome";
import { Toaster } from "react-hot-toast";
import { AppContext, API, useUtils, Loader, toast } from "../../utils";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import dayjs from "dayjs";

const Dashboard = () => {
  const [state, setState] = useContext(AppContext);
  const [status, setStatus] = useState("loading");
  const [reportingData, setReportingData] = useState(null);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const utils = useUtils();

  /*useEffect(() => {
    const storedPage = localStorage.getItem('currentPage');
    const storedData = localStorage.getItem('reportingData');
    if (storedPage) {
      setState((s) => ({ ...s, currentPage: JSON.parse(storedPage) }));
    }
    if (storedData) {
      setReportingData(JSON.parse(storedData));
      setState((s) => ({ ...s, currentKpis: JSON.parse(storedData) }));
    }
  }, []);*/

  useEffect(() => {
    const storedPage = localStorage.getItem('currentPage');
    const storedData = localStorage.getItem('reportingData');
  
    // Vérification et récupération de la page stockée
    if (storedPage) {
      try {
        const parsedPage = JSON.parse(storedPage);
        setState((s) => ({ ...s, currentPage: parsedPage }));
      } catch (e) {
        console.error("Échec du parsing de currentPage depuis localStorage", e);
        localStorage.removeItem('currentPage'); // Supprime les données invalides
      }
    }
  
    // Vérification et récupération des données de reporting stockées
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setReportingData(parsedData);
        setState((s) => ({ ...s, currentKpis: parsedData }));
      } catch (e) {
        console.error("Échec du parsing de reportingData depuis localStorage", e);
        localStorage.removeItem('reportingData'); // Supprime les données invalides
      }
    }
  }, []);
  

  useEffect(() => {
    if (state.pages && params.pageId && (!state.currentPage || state.currentPage.page.id !== params.pageId)) {
      const page = (state.pages || []).find(el => el.page_id == params.pageId);
      if (page) {
        localStorage.setItem('currentPage', JSON.stringify(page));
        setState((s) => ({ ...s, currentPage: page }));
      }
    }
    loadKpis();
  }, [params.pageId, state.reportingPeriod]);

  useEffect(() => {
    const handleEvent = (event) => {
      const data = event?.detail?.data;
      if (data?.type === 'job' && data?.status === 'done') {
        loadKpis();
      }
    };
    window.addEventListener('diggow.msg', handleEvent);
    return () => {
      window.removeEventListener('diggow.msg', handleEvent);
    };
  }, []);

  /*const loadKpis = async () => {
    if (!state.currentPage) return;
    setStatus("loading");
    try {
      const res = await API.pages.kpis(state.currentPage.id, {
        from: dayjs(state.reportingPeriod[0]).format("YYYY-MM-DD"),
        to: dayjs(state.reportingPeriod[1]).format("YYYY-MM-DD"),
      });
      localStorage.setItem('reportingData', JSON.stringify(res.data?.data));
      setReportingData(res.data?.data);
      setState((s) => ({ ...s, currentKpis: res.data?.data }));
      setStatus(res.data?.data?.metrics ? "" : "loading");
    } catch (e) {
      console.error(e);
      const fullErrorMessage = e?.response;
      if (fullErrorMessage.status === 500) {
        toast.error('The user must be an administrator, editor, or moderator of the page in order to impersonate it');
      } else if (fullErrorMessage?.data?.message) {
        toast.error(fullErrorMessage?.data?.message);
      } else {
        toast.error('Désolé, la page que vous recherchez n\'est pas disponible pour le moment.');
      }
    }
  };*/
  
  const loadKpis = async () => {
    if (!state.currentPage) return;
    setStatus("loading");
    try {
      const res = await API.pages.kpis(state.currentPage.id, {
        from: dayjs(state.reportingPeriod[0]).format("YYYY-MM-DD"),
        to: dayjs(state.reportingPeriod[1]).format("YYYY-MM-DD"),
      });
  
      const data = res.data?.data;
  
      // Vérifier si les données sont trop grandes pour LocalStorage
      if (JSON.stringify(data).length > 5000000) { // Limite approximative de 5 MB
        console.warn("Les données sont trop volumineuses pour être stockées dans LocalStorage");
      } else {
        localStorage.setItem('reportingData', JSON.stringify(data)); // Stocker uniquement si la taille est acceptable
      }
  
      setReportingData(data);
      setState((s) => ({ ...s, currentKpis: data }));
      setStatus(data?.metrics ? "" : "loading");
    } catch (e) {
      console.error(e);
      const fullErrorMessage = e?.response;
      if (fullErrorMessage?.status === 500) {
        toast.error('The user must be an administrator, editor, or moderator of the page in order to impersonate it');
      } else if (fullErrorMessage?.data?.message) {
        toast.error(fullErrorMessage?.data?.message);
      } else {
        toast.error('Désolé, la page que vous recherchez n\'est pas disponible pour le moment.');
      }
    }
  };
  

  
  if (!state.currentPage && !JSON.parse(localStorage.getItem('currentPage'))) {
    return <Welcome />;
  }

  return (
    <div>
      <Toaster />
      {status === "loading" ? (
        <>
          <div className="text-center m-5">
            {reportingData?.status === "feeding"
              ? "Récupération en cours..."
              : ""}
          </div>
          <Loader />
        </>
      ) : (
        <div className="tab-contents">
          {state.currentPage ? (
            <ReportingRoutes reportingData={reportingData} />
          ) : (
            <Welcome />
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
