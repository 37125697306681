import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import SortableList, { SortableItem } from 'react-easy-sort'
import { Messages } from 'primereact/messages';
import arrayMoveImmutable from 'array-move';
import { API } from "../../utils";

import globeIcon from "../../images/svg/Globe.svg";
import avatarIcon from "../../images/images/avatar.svg";
import PhotoIcon from "../../images/svg/photo_empty.svg";
import uploadPhoto from "../../images/svg/upload_photo.svg";


const CreatePostIG = (props) => {

    const msgs = useRef(null);
    const toast = useRef(null);
    const { t } = useTranslation();
    const { pageId } = useParams(); 

    const [photos, setPhotos] = useState([]);
    const [videos, setVideos] = useState([]);
    const [getError, setError] = useState('');
    const [previews, setPreview] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    const page = props?.pages?.find(page => page.page_id === parseInt(pageId));
    
    const name = page?.page?.name ?? '';
    const picture = page?.page?.picture ?? avatarIcon;
    const socialType = page ? page.social_type : 'Inconnu';  

    useEffect(() => {
        if (msgs.current) {
            msgs.current.clear();
            msgs.current.show([
                { sticky: true, severity: 'error', detail: getError, closable: true }
            ]);            
        }
    }, [getError]);
    

    const showError = (errorMessage) => {
        toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: errorMessage,
            life: 3000
        });
    };

    const handleFileChange = (event) => {

        setError('');
        setVideos([]);
        setPhotos([]);
        setPreview([]);

        const files = event.target.files;
        
        // Vérifie si plus de 10 fichiers sont sélectionnés
        if (files.length >= 10) {
            setError("You can only upload up to 10 files.");
            // Réinitialise l'input file pour permettre de nouvelles sélections
            event.target.value = "";
            return;
        }

        const videos = [];
        const photos = [];
        const preview_array = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // Crée une URL de prévisualisation
            const previewUrl = URL.createObjectURL(file);

            if (file.type.startsWith('video/')) {
                console.log('Le fichier est une vidéo:', file.name);
                videos.push(file); // Ajoute aux vidéos
                preview_array.push({ type: 'video', name: file.name, previewUrl, file });
            } else if (file.type.startsWith('image/')) {
                console.log('Le fichier est une photo:', file.name);
                photos.push(file); // Ajoute aux photos
                preview_array.push({ type: 'image', name: file.name, previewUrl, file });
            } else {
                console.log('Le fichier n\'est ni une vidéo ni une photo:', file.name);
            }
        }

        // Met à jour les états
        setVideos((prevVideos) => [...prevVideos, ...videos]);
        setPhotos((prevPhotos) => [...prevPhotos, ...photos]);
        setPreview((prevPreview) => [...prevPreview, ...preview_array]);
    };       

    const clearFile = () => {   
        setVideos([]);
        setPhotos([]);
        setPreview([]);
        // Rafraîchir les inputs file en les remettant à vide temporairement
        const fileInput = document.getElementById("upload_file");
        if (fileInput) {
            fileInput.value = "";
        }
    }; 

    const MAX_FILENAME_LENGTH = 15;
    const MAX_FILENAMEREEL_LENGTH = 30;  

    const onSortEnd = (oldIndex, newIndex) => {
        setPreview((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };
  
    const removePreview = (index) => {
        setPreview((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
    };   

    // Fonction pour soumettre les données avec gestion du chargement
    const handleSubmit = async () => {
        if (previews.length === 0) {
            showError("Please upload at least one file before publishing.");
            return;
        }
    
        try {
            setIsLoading(true);
            console.log('previews', previews);
            
            // Préparer les données pour l'API
            const formData = new FormData();
            /*previews.forEach((file, index) => {
                if (file.type === 'image') {
                    formData.append(`images[${index}]`, file.file); // Ajoutez le fichier original
                } else if (file.type === 'video') {
                    formData.append(`videos[${index}]`, file.file); // Ajoutez le fichier original
                }
            });
    
            // Ajoutez d'autres paramètres si nécessaire (par ex. : caption, scheduling info, etc.)
            formData.append('pageId', pageId);
            formData.append('socialType', socialType);*/

            if (previews) {
                previews.forEach((file, index) => {
                    const filetype = file.type;
                    const fileUpload = file.file; 
                    
                    formData.append("types[]", filetype);
                    formData.append("files[]", fileUpload);
                });              
            }
    
            // Appel API
            let response = await API.planificates.publishIG(formData);
    
            // Vérifiez la réponse
            if (response.status === 200) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Post published successfully!',
                    life: 3000,
                });
    
                // Réinitialiser les états si nécessaire
                clearFile();
            } else {
                showError(response?.data?.message || "An error occurred while publishing.");
            }
        } catch (error) {
            showError(error.message || "An unexpected error occurred.");
        } finally {
            setIsLoading(false);
        }
    };
    
    
    return (
        <div className="block_createPost">
            <Toast ref={toast}></Toast>

            <h1 className="font-semibold text-[#101828] text-[30px] pb-4 mb-6 border-b-2 border-[#EAECF0]">{t("Planification.create_post")}</h1>
            <div className="grid grid-cols-2 gap-4">

                <div className="col border-r-2 border-[#EAECF0] relative">
                    <div className="publishPost px-6">
                        <div className="multimedia_content px-8 py-4">
                            <h2 className="font-semibold text-[#101828] text-[18px]">{t("Planification.multimedia_content")}</h2>
                            <span className="font-normal text-[#344054] text-[12px] block my-2">{t("Planification.share_photos_video")}</span>

                            <div className="columns-2">
                                <div className="btn_style_upload flex bg-white border border-[#D0D5DD] rounded-[8px] rop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)]">
                                    <label htmlFor="upload_file" className="flex items-center justify-center px-4 py-2 font-semibold text-[#344054] text-[14px] w-full cursor-pointer">
                                        <img className="mr-2" src={uploadPhoto} />
                                        {previews.length > 1 ? (
                                            `${previews.length} Files`
                                            ) : previews.length > 0 ? (
                                            (previews[0].name && previews[0].name.length > MAX_FILENAME_LENGTH
                                                ? `${previews[0].name.slice(0, MAX_FILENAME_LENGTH)}...`
                                                : previews[0].name) 
                                            ) : (
                                            t("Planification.add_photo_reel") 
                                        )}
                                    </label>

                                    {/* Bouton Effacer pour supprimer le fichier */}
                                    {previews.length > 0 && (
                                        <button 
                                            onClick={clearFile} 
                                            className="m-auto p-2 text-[12px] pi pi-times"
                                        >
                                        </button>
                                    )}
                                    <input 
                                        multiple
                                        type="file" 
                                        id="upload_file" 
                                        className="hidden"
                                        accept="image/*,video/*"
                                        onChange={handleFileChange} 
                                    />
                                </div>
                                
                            </div>

                            {/* {previews.length > 1 && (
                                <SortableList onSortEnd={onSortEnd} className="list_vertical" draggedItemClassName="dragged">
                                    {previews.map((item, index) => (
                                        <SortableItem key={index}>
                                            <div className="relative preview-item bg-[#F2F4F7]">
                                                {item.type === 'image' ? (
                                                    <img
                                                        src={item.previewUrl}
                                                        className="max-h-full max-w-full object-cover h-full"
                                                        alt="Uploaded image preview"
                                                    />
                                                ) : (
                                                    <video
                                                        src={item.previewUrl}
                                                        controls
                                                        className="max-h-full max-w-full"
                                                        alt="Uploaded video preview"
                                                    />
                                                )}
                                                <button
                                                    onClick={() => removePreview(index)}
                                                    className="absolute top-[-10px] right-0 font-semibold text-[#000] text-[14px] border border-[#D0D5DD] rounded-[8px] w-[35px] h-[25px] bg-white"
                                                >
                                                    X
                                                </button>
                                            </div>
                                        </SortableItem>
                                    ))}
                                </SortableList>
                            )} */}
                        
                            {getError && 
                                <div className="block_error">                            
                                    <Messages ref={msgs} />
                                </div>
                            }

                        </div>                        
                        
                        <div className="scheduling_block px-8 py-4">
                            <h2 className="font-semibold text-[#101828] text-[18px]">{t("Planification.scheduling_options")}</h2>
                            <span className="font-normal text-[#344054] text-[12px] block my-2">{t("Planification.scheduling_txt")}</span>
                            <button 
                                onClick={handleSubmit} 
                                className="font-bold text-[#FFF] text-[12px] bg-[#7F56D9] mt-5 px-5 py-2 rounded-[6px]"
                            >
                                Publish
                            </button>
                        </div>

                    </div>
                    
                    {isLoading &&
                    <div className="loading_block flex items-center justify-center backdrop-blur-[10px] absolute top-0 left-0 right-0 bottom-0">
                        <div className="">
                            <h2 className="font-semibold text-center text-[#101828] text-[16px]">
                                {t("Planification.msg_loading")}<br/>
                                {t("Planification.msg_loading1")}<br/>
                                {t("Planification.msg_loading2")}
                            </h2>
                            <span className="loader"></span>
                        </div>
                    </div>
                    }
                </div>
            
                <div className="col">

                    {previews.length > 0 && (
                        <a
                            href={previews}
                            className="table border border-[#d0d5dd] rounded-[8px] text-[#344054] text-[12px] font-semibold drop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)] px-3 py-3"
                            target="_blank"
                        >
                        {t("Planification.preview_on")} {socialType}
                        </a>
                    )}
                    
                    <div className="name_user my-4">
                        <span className="text-[#344054] font-semibold text-[16px] flex items-center">
                            <img className="w-10 h-10 rounded-full mr-2" src={picture} />
                            {name}
                            <img className="ml-2" src={globeIcon} />
                        </span>
                    </div>

                    <div className="photo_post">
                        <div className="box_photoPost w-full bg-[#F2F4F7] p-5 min-h-[450px] flex items-center justify-center">
                            {previews.length > 1 ? (
                                <SortableList onSortEnd={onSortEnd} className="list_grid" draggedItemClassName="dragged">
                                    {previews.map((item, index) => (
                                        <SortableItem key={index}>
                                            <div className="relative preview-item bg-[#F2F4F7]">
                                                {item.type === 'image' ? (
                                                    <img
                                                        src={item.previewUrl}
                                                        className="max-h-full max-w-full object-cover h-full"
                                                        alt="Uploaded image preview"
                                                    />
                                                ) : (
                                                    <video
                                                        src={item.previewUrl}
                                                        controls
                                                        className="max-h-full max-w-full"
                                                        alt="Uploaded video preview"
                                                    />
                                                )}
                                                <button
                                                  onClick={() => removePreview(index)}
                                                  className="absolute top-[-10px] right-0 font-semibold text-[#000] text-[14px] border border-[#D0D5DD] rounded-[8px] w-[35px] h-[25px] bg-white"
                                                >
                                                  X
                                                </button>
                                            </div>
                                        </SortableItem>
                                    ))}
                                </SortableList>
                            ) : previews.length === 1 ? (
                                previews[0].type === 'image' ? (
                                    <img
                                        src={previews[0].previewUrl}
                                        className="max-h-full max-w-full object-cover h-full"
                                        alt="Uploaded image preview"
                                    />
                                ) : (
                                    <video
                                        src={previews[0].previewUrl}
                                        controls
                                        className="max-h-full max-w-full"
                                        alt="Uploaded video preview"
                                    />
                                )
                            ) : (
                                <img 
                                    src={PhotoIcon} 
                                    className="max-h-full max-w-full"
                                    alt="Default preview"
                                />
                            )}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default CreatePostIG;
